import { useState } from 'react'

import { API_ENDPOINT } from '../context/global'
import axios from 'axios'
import { useEffect } from 'react'

function useProdutos() {
  const [produtos, setProdutos] = useState([])
  const [loadingProducts, setLoadingProducts] = useState(false)
  const [produtosErro, setProdutosErro] = useState('')

  const fetchProdutos = async () => {
    setProdutosErro('')
    setLoadingProducts(true)
    try {
      const { data } = await axios.get(`${API_ENDPOINT}/v1/produtos`)

      setProdutos(data.produtos)

      setLoadingProducts(false)
    } catch (error) {
      console.log(error)
      setLoadingProducts(false)
    }
  }

  const fetchProdutoUnico = async (id) => {
    setProdutosErro('')
    setLoadingProducts(true)
    try {
      const { data } = await axios.get(`${API_ENDPOINT}/v1/produtos/${id}`)
      setLoadingProducts(false)
      return data.produto
    } catch (error) {
      console.log(error)
      setLoadingProducts(false)
      return false
    }
  }

  const deleteProduto = async (id) => {
    setProdutosErro('')
    setLoadingProducts(true)
    try {
      await axios.delete(`${API_ENDPOINT}/v1/produtos/${id}`)
      await fetchProdutos()
      setLoadingProducts(false)
      return true
    } catch (error) {
      console.log(error)
      setProdutosErro(error.response.data.msg)
      setLoadingProducts(false)
      return false
    }
  }

  useEffect(() => {
    fetchProdutos()
  }, [])

  return {
    produtos,
    fetchProdutos,
    loadingProducts,
    fetchProdutoUnico,
    deleteProduto,
    produtosErro,
  }
}

export default useProdutos
