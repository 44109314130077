import axios from 'axios'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { useEffect } from 'react'
import { useState } from 'react'
import { API_ENDPOINT } from '../../context/global'
import { format } from 'date-fns'
import useLocalState from '../../utils/localState'
import { Container } from '@mui/material'

import { AddButton, DefaultList } from '../../components/ui'

function ListaEventos() {
  const [eventos, setEventos] = useState([])
  const { alert, showAlert, hideAlert, loading, setLoading } = useLocalState()

  const navigate = useNavigate()

  const fetchEventos = async () => {
    setLoading(true)
    try {
      const { data } = await axios.get(`${API_ENDPOINT}/v1/eventos`)

      setEventos(data.eventos)
      setLoading(false)
    } catch (error) {
      setLoading(false)

      console.log(error)
    }
  }

  const removeEvento = async (id) => {
    hideAlert()
    if (id) {
      try {
        await axios.delete(`${API_ENDPOINT}/v1/eventos/${id}`)
        showAlert({ text: 'Evento excluído com sucesso.', type: 'success' })
        await fetchEventos()
      } catch (error) {
        showAlert({ text: 'Erro ao excluir evento.' })
        console.log(error)
      }
    } else {
      showAlert({ text: 'Erro ao excluir: ID de exclusão indefinido.' })
    }
  }

  const handleClick = (id) => {
    navigate(`/eventos/${id}`)
  }

  useEffect(() => {
    fetchEventos()
  }, [])

  const listItems = eventos.map((evento) => {
    return {
      id: evento._id,
      primary: evento.nome,
      secondary: format(new Date(evento.data), 'dd/MM/y HH:mm'),
    }
  })

  return (
    <div className='page-container'>
      <Container maxWidth={false}>
        <h5 className='page-title'>Lista de Eventos</h5>
        <div className='w-24 h-1 bg-sky-400 mx-auto mb-5'></div>

        {alert.show && (
          <div className={`alert alert-${alert.type}`}>{alert.text}</div>
        )}
        <div className='botoes text-center flex items-center justify-center'>
          <AddButton destination='/eventos/novo' />
        </div>
        {loading ? (
          <div className='loading'></div>
        ) : (
          <DefaultList
            items={listItems}
            handleDelete={removeEvento}
            handleClick={handleClick}
          />
        )}
      </Container>
    </div>
  )
}

export default ListaEventos
