import axios from 'axios'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { API_ENDPOINT } from '../../context/global'
import { BackButton } from '../../components/ui'
import useLocalState from '../../utils/localState'

function MostraProduto() {
  const { id } = useParams()
  const [erro, setErro] = useState('')
  const [produto, setProduto] = useState({})
  const { loading, setLoading } = useLocalState()

  const fetchProduto = async () => {
    setErro('')
    setLoading(true)
    try {
      const { data } = await axios.get(`${API_ENDPOINT}/v1/produtos/${id}`)
      setProduto(data.produto)
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)

      setErro('Erro ao carregar os dados')
    }
  }

  useEffect(() => {
    fetchProduto()
  }, [])

  if (loading)
    return (
      <div className='page-container'>
        <div className='loading'></div>
      </div>
    )
  if (erro)
    return (
      <div className='page-container'>
        <h3>{erro}</h3>
        <div className='botoes text-center flex items-center justify-center'>
          <BackButton destination='/produtos' />
        </div>
      </div>
    )

  return (
    <div className='page-container'>
      <div className='botoes text-center flex items-center justify-center'>
        <BackButton destination='/produtos' />
      </div>
      <h4 className='page-title'>{produto.nome}</h4>
      <p className='data'>
        <span className='ml-1'>
          {(produto.preco / 100).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })}
        </span>
      </p>
    </div>
  )
}

export default MostraProduto
