import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import useVendas from '../../hooks/useVendas'
import { numberToCurrency } from '../../utils/currencyAndDate'

import { Bar2D, Doughnut2D } from '../charts'

function ResumoEvento({ evento }) {
  const [vendasEvento, setVendasEvento] = useState([])
  const { vendasPorEvento, loadingVendas, calculaEstatVendas } = useVendas()
  const [estatisticas, setEstatisticas] = useState({
    numVendas: 0,
    totalVendas: 0,
    totalUnidades: 0,
    pagamentos: [],
    produtosUnidade: [],
    produtosValor: [],
  })

  // const calculaEstatisticas = () => {
  //   let totalVendas = 0
  //   let totalUnidades = 0
  //   let pagamentos = []
  //   let produtosUnidade = []
  //   let produtosValor = []
  //   vendasEvento.map((venda) => {
  //     totalVendas += venda.total

  //     //PAGAMENTOS
  //     const item = pagamentos.find(
  //       (pagamento) => pagamento.label === venda.pagamento
  //     )
  //     if (!item) {
  //       const newItem = {
  //         label: venda.pagamento,
  //         value: venda.total / 100,
  //       }
  //       pagamentos.push(newItem)
  //     } else {
  //       item.value += venda.total / 100
  //     }

  //     //PRODUTOS
  //     venda.itens.map((vendaItem) => {
  //       totalUnidades += vendaItem.quantidade

  //       const prodUnidade = produtosUnidade.find(
  //         (produto) => produto.id === vendaItem.produto._id
  //       )
  //       if (!prodUnidade) {
  //         const newProd = {
  //           id: vendaItem.produto._id,
  //           label: vendaItem.produto.nome,
  //           value: vendaItem.quantidade,
  //         }
  //         produtosUnidade.push(newProd)
  //       } else {
  //         prodUnidade.value += vendaItem.quantidade
  //       }

  //       const prodValor = produtosValor.find(
  //         (produto) => produto.id === vendaItem.produto._id
  //       )
  //       if (!prodValor) {
  //         const newProd = {
  //           id: vendaItem.produto._id,
  //           label: vendaItem.produto.nome,
  //           value: (vendaItem.quantidade * vendaItem.preco) / 100,
  //         }
  //         produtosValor.push(newProd)
  //       } else {
  //         prodValor.value += (vendaItem.quantidade * vendaItem.preco) / 100
  //       }

  //       return vendaItem
  //     })

  //     return venda
  //   })

  //   produtosUnidade.sort((a, b) => b.value - a.value)
  //   produtosValor.sort((a, b) => b.value - a.value)

  //   setEstatisticas({
  //     numVendas: vendasEvento.length,
  //     totalVendas,
  //     pagamentos,
  //     produtosUnidade,
  //     produtosValor,
  //     totalUnidades,
  //   })
  // }

  useEffect(() => {
    if (evento) {
      const vendas = vendasPorEvento(evento._id)
      setVendasEvento(vendas)
    }
  }, [evento, vendasPorEvento])

  useEffect(() => {
    setEstatisticas(calculaEstatVendas(vendasEvento))
  }, [vendasEvento])

  if (loadingVendas) {
    return <div className='loading'></div>
  }

  return (
    <div className='text-left'>
      <div className='flex justify-center flex-wrap'>
        <div className='m-2'>
          <Doughnut2D
            caption='Vendas'
            data={estatisticas.pagamentos}
            subCaption={`${estatisticas.numVendas} VENDAS | ${numberToCurrency(
              estatisticas.totalVendas
            )}`}
          />
        </div>

        <div className='m-2 flex-1'>
          <Bar2D
            caption='Produtos Vendidos - Unidades'
            data={estatisticas.produtosUnidade}
            subCaption={`${estatisticas.totalUnidades} UNIDADES VENDIDAS`}
          />
        </div>
        <div className='m-2'>
          <Bar2D
            caption='Produtos Vendidos - Faturamento'
            data={estatisticas.produtosValor}
            numberPrefix='R$ '
          />
        </div>
      </div>
      <br />
    </div>
  )
}

export default ResumoEvento
