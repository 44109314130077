import axios from 'axios'
import {
  SET_LOADING,
  SAVE_USER,
  LOGOUT,
  SET_SHOWLINKS,
} from '../actions/global'

import { API_ENDPOINT } from '../context/global'

const logout = async () => {
  await axios.get(`${API_ENDPOINT}/v1/auth/logout`)
}

const globalReducer = (state, action) => {
  if (action.type === SET_LOADING) {
    return { ...state, isLoading: action.payload }
  }

  if (action.type === SAVE_USER) {
    return { ...state, user: action.payload, isLoading: false }
  }

  if (action.type === LOGOUT) {
    logout()
    return { ...state, user: null }
  }

  if (action.type === SET_SHOWLINKS) {
    return { ...state, showLinks: action.payload }
  }

  throw new Error('no matching action type')
}
export default globalReducer
