import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'

function BackButton({ destination }) {
  return (
    <Button
      component={Link}
      to={destination}
      startIcon={<ChevronLeftIcon />}
      variant='text'
    >
      Voltar
    </Button>
  )
}

export default BackButton
