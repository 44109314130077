import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { useState } from 'react'
import useLocalState from '../../utils/localState'
import useProdutos from '../../hooks/useProdutos'
import { AddButton, DefaultList } from '../../components/ui'
import { numberToShortCurrency } from '../../utils/currencyAndDate'
import { Container, TextField, InputAdornment } from '@mui/material'

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'

function ListaProdutos() {
  const [produtosMostrados, setProdutosMostrados] = useState([])
  const { alert, showAlert, hideAlert } = useLocalState()
  const [search, setSearch] = useState('')
  const { produtos, deleteProduto, produtosErro, loadingProducts } =
    useProdutos()

  const navigate = useNavigate()

  useEffect(() => {
    if (search === '') {
      setProdutosMostrados(produtos)
      return
    }

    if (search !== '' && produtos.length > 0) {
      setProdutosMostrados(
        produtos.filter((produto) =>
          produto.nome.toLowerCase().includes(search.toLowerCase())
        )
      )
    }
  }, [search, produtos])

  useEffect(() => {
    if (produtosErro) showAlert({ text: produtosErro })
    else hideAlert()
  }, [produtosErro])

  const handleDelete = async (id) => {
    deleteProduto(id)
  }

  const handleClick = (id) => {
    navigate(`/produtos/${id}`)
  }

  const listaProdutos = produtosMostrados.map((produto) => {
    return {
      id: produto._id,
      primary: produto.nome,
      secondary: numberToShortCurrency(produto.preco),
    }
  })

  if (loadingProducts)
    return (
      <div className='page-container'>
        <div className='loading'></div>
      </div>
    )

  return (
    <div className='page-container'>
      <Container maxWidth={false}>
        <h5 className='page-title'>Lista de Produtos</h5>
        <div className='w-24 h-1 bg-sky-400 mx-auto mb-5'></div>

        {alert.show && (
          <div className={`alert alert-${alert.type}`}>{alert.text}</div>
        )}
        <div className='botoes text-center flex items-center justify-center'>
          <AddButton destination='/produtos/novo' />
        </div>
        <div className='filtros'>
          <TextField
            size='small'
            variant='filled'
            label='Pesquisar'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchOutlinedIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
        </div>

        <div className='lista mt-3'>
          <DefaultList
            items={listaProdutos}
            handleDelete={handleDelete}
            handleClick={handleClick}
          />
        </div>
      </Container>
    </div>
  )
}

export default ListaProdutos
