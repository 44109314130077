import React, { useState } from 'react'

function Tabs({ tabs, defaultTab = '', changeComponent }) {
  const [activeTab, setActiveTab] = useState(defaultTab)

  const changeTab = (tabName, tabComponent) => {
    if (activeTab !== tabName) {
      setActiveTab(tabName)
      changeComponent(tabComponent)
    }
  }

  return (
    <div className='tabs-container'>
      <div className='tabs-menu'>
        {tabs.map((tab, index) => {
          return (
            <div
              key={`tab${index}`}
              className={`tab-item ${activeTab === tab.name && 'active'}`}
              onClick={() => changeTab(tab.name, tab.component)}
            >
              {tab.name}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Tabs
