import React from 'react'
import { TextField } from '@mui/material'
const TextInput = ({
  label,
  name,
  value,
  onChange,
  type = 'text',
  size,
  inputRef,
  compact = false,
  variant = 'outlined',
}) => {
  return (
    <TextField
      id={name}
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      variant={variant}
      type={type}
      inputRef={inputRef}
      sx={{ my: 1, mr: 1, width: size || 'auto' }}
      size={compact ? 'small' : 'medium'}
    />
  )
}

export default TextInput
