import React from 'react'
import { Box } from '@mui/material'

function Form({ children, action, method, onSubmit, title, flexRow = false }) {
  return (
    <Box
      sx={{
        boxShadow: 3,
        bgcolor: '#222',
        p: 3,
        mx: 'auto',
        maxWidth: 'sm',
        my: 2,
      }}
    >
      <form
        action={action || ''}
        method={method || 'POST'}
        onSubmit={
          onSubmit ||
          ((e) => {
            e.preventDefault()
          })
        }
      >
        <div className={`uppercase text-md text-left mb-3 tracking-wider`}>
          {title || ''}
          <div className='w-24 h-1 bg-sky-400'></div>
        </div>
        <div
          className={`flex ${
            flexRow ? 'flex-row flex-wrap' : 'flex-col'
          } gap-1 `}
        >
          {children}
        </div>
      </form>
    </Box>
  )
}

export default Form
