import React, { useState } from 'react'
import useLocalState from '../../utils/localState'
import axios from 'axios'
import { API_ENDPOINT } from '../../context/global'
import { BackButton, Form, TextInput } from '../../components/ui'
import { Container } from '@mui/material'
import { LoadingButton } from '@mui/lab'

function CriaProduto() {
  const { loading, setLoading, alert, showAlert, hideAlert } = useLocalState()
  const [nome, setNome] = useState('')
  const [preco, setPreco] = useState('')

  const handleSubmit = async (e) => {
    hideAlert()
    setLoading(true)
    e.preventDefault()
    if (!nome || !preco) {
      showAlert({ text: 'Por favor informe Nome e Preço para o produto.' })
      setLoading(false)
      return
    }
    try {
      const { data: respData } = axios.post(`${API_ENDPOINT}/v1/produtos`, {
        nome,
        preco,
      })
      showAlert({ text: 'Produto Adicionado com Sucesso !', type: 'success' })
      setLoading(false)
      setNome('')
      setPreco('')
    } catch (error) {
      setLoading(false)
      showAlert({ text: 'Erro ao adicionar produto' })
      console.log(error)
    }
  }

  return (
    <div className='page-container'>
      <Container maxWidth={false}>
        <h5 className='page-title'>Lista de Produtos</h5>
        <div className='w-24 h-1 bg-sky-400 mx-auto mb-5'></div>

        <div className='botoes text-center flex items-center justify-center'>
          <BackButton destination='/produtos' />
        </div>
        <div>
          {alert.show && (
            <div className={`alert alert-${alert.type}`}>{alert.text}</div>
          )}

          <Form title='Adicionar Produto' onSubmit={handleSubmit}>
            <TextInput
              label='Nome do produto'
              name='nome'
              value={nome}
              onChange={(e) => setNome(e.target.value)}
              size='w-80'
            />

            <TextInput
              label='Preço'
              name='preco'
              value={preco}
              onChange={(e) => setPreco(e.target.value)}
              size='w-56'
            />

            <div>
              <LoadingButton
                loading={loading}
                variant='contained'
                type='submit'
                sx={{ mt: 1, fontWeight: 'bold' }}
              >
                Salvar
              </LoadingButton>
            </div>
          </Form>
        </div>
      </Container>
    </div>
  )
}

export default CriaProduto
