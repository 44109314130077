import React, { useState } from 'react'
import axios from 'axios'
import useLocalState from '../../utils/localState'
import { API_ENDPOINT } from '../../context/global'
import { BackButton, Form, TextInput } from '../../components/ui'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TextField } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import { LoadingButton } from '@mui/lab'

function CriaEvento() {
  const { loading, setLoading, alert, showAlert, hideAlert } = useLocalState()
  const [nome, setNome] = useState('')
  const [data, setData] = useState(new Date())

  const handleSubmit = async (e) => {
    hideAlert()
    setLoading(true)
    e.preventDefault()
    if (!nome || !data) {
      showAlert({ text: 'Por favor informe Nome e Data para o evento.' })
      setLoading(false)
      return
    }
    try {
      const { data: respData } = axios.post(`${API_ENDPOINT}/v1/eventos`, {
        nome,
        data,
      })
      showAlert({ text: 'Evento Adicionado com Sucesso !', type: 'success' })
      setLoading(false)
      setNome('')
      setData(new Date())
    } catch (error) {
      setLoading(false)
      showAlert({ text: 'Erro ao adicionar evento' })
      console.log(error)
    }
  }

  return (
    <div className='page-container'>
      <h4 className='page-title'>Novo Evento</h4>
      <div className='w-24 h-1 bg-sky-400 mx-auto mb-5'></div>

      <div className='botoes text-center flex items-center justify-center mb-3'>
        <BackButton destination='/eventos' />
      </div>
      <div>
        {alert.show && (
          <div className={`alert alert-${alert.type}`}>{alert.text}</div>
        )}

        <Form title='Adicionar Evento' onSubmit={handleSubmit}>
          <TextInput
            label='Nome do evento'
            name='nome'
            value={nome}
            onChange={(e) => setNome(e.target.value)}
            size='w-80'
          />

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              label='Data e Hora do Evento'
              value={data}
              onChange={setData}
              renderInput={(params) => (
                <TextField sx={{ mt: 2, width: '250px' }} {...params} />
              )}
              inputFormat='dd/MM/yyyy HH:mm'
              ampm={false}
            />
          </LocalizationProvider>

          <div>
            <LoadingButton
              loading={loading}
              variant='contained'
              type='submit'
              sx={{ mt: 1, fontWeight: 'bold' }}
            >
              Salvar
            </LoadingButton>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default CriaEvento
