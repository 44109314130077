import React from 'react'

import { API_ENDPOINT } from '../context/global'
import axios from 'axios'
import { useEffect } from 'react'
import { useState } from 'react'
import { useCallback } from 'react'

function useVendas() {
  const [vendas, setVendas] = useState([])
  const [loadingVendas, setLoadingVendas] = useState(false)
  const [vendasErro, setVendasErro] = useState('')

  const fetchVendas = async () => {
    setVendasErro('')
    setLoadingVendas(true)
    try {
      const { data } = await axios.get(`${API_ENDPOINT}/v1/vendas`)

      setVendas(data.vendas)

      setLoadingVendas(false)
    } catch (error) {
      console.log(error)
      setLoadingVendas(false)
    }
  }

  const vendasPorEvento = useCallback(
    (evento) => {
      return vendas.filter((venda) => {
        return venda.evento === evento
      })
    },
    [vendas]
  )

  const deleteVenda = async (id) => {
    setVendasErro('')
    setLoadingVendas(true)
    try {
      await axios.delete(`${API_ENDPOINT}/v1/vendas/${id}`)
      await fetchVendas()
      setLoadingVendas(false)
    } catch (error) {
      console.log(error)
      setVendasErro(error.response.data.msg)
      setLoadingVendas(false)
    }
  }

  const calculaEstatVendas = (vendasEvento) => {
    setLoadingVendas(true)
    let totalVendas = 0
    let totalUnidades = 0
    let pagamentos = []
    let produtosUnidade = []
    let produtosValor = []
    vendasEvento.map((venda) => {
      totalVendas += venda.total

      //PAGAMENTOS
      const item = pagamentos.find(
        (pagamento) => pagamento.label === venda.pagamento
      )
      if (!item) {
        const newItem = {
          label: venda.pagamento,
          value: venda.total / 100,
        }
        pagamentos.push(newItem)
      } else {
        item.value += venda.total / 100
      }

      //PRODUTOS
      venda.itens.map((vendaItem) => {
        totalUnidades += vendaItem.quantidade

        const prodUnidade = produtosUnidade.find(
          (produto) => produto.id === vendaItem.produto._id
        )
        if (!prodUnidade) {
          const newProd = {
            id: vendaItem.produto._id,
            label: vendaItem.produto.nome,
            value: vendaItem.quantidade,
          }
          produtosUnidade.push(newProd)
        } else {
          prodUnidade.value += vendaItem.quantidade
        }

        const prodValor = produtosValor.find(
          (produto) => produto.id === vendaItem.produto._id
        )
        if (!prodValor) {
          const newProd = {
            id: vendaItem.produto._id,
            label: vendaItem.produto.nome,
            value: (vendaItem.quantidade * vendaItem.preco) / 100,
          }
          produtosValor.push(newProd)
        } else {
          prodValor.value += (vendaItem.quantidade * vendaItem.preco) / 100
        }

        return vendaItem
      })

      return venda
    })

    produtosUnidade.sort((a, b) => b.value - a.value)
    produtosValor.sort((a, b) => b.value - a.value)
    setLoadingVendas(false)

    return {
      numVendas: vendasEvento.length,
      totalVendas,
      pagamentos,
      produtosUnidade,
      produtosValor,
      totalUnidades,
    }
  }

  useEffect(() => {
    fetchVendas()
  }, [])

  return {
    vendas,
    loadingVendas,
    vendasPorEvento,
    fetchVendas,
    deleteVenda,
    calculaEstatVendas,
  }
}

export default useVendas
