import React from 'react'
import { API_ENDPOINT, useGlobalContext } from '../context/global'
import useLocalState from '../utils/localState'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { SAVE_USER } from '../actions/global'
import { LoadingButton } from '@mui/lab'

import axios from 'axios'
import { Form, SubmitButton, TextInput } from '../components/ui'

function Login() {
  const navigate = useNavigate()
  const [values, setValues] = useState({
    email: '',
    password: '',
  })

  const { globalDispatch } = useGlobalContext()

  const { alert, showAlert, loading, setLoading, hideAlert } = useLocalState()

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    hideAlert()
    setLoading(true)
    const { email, password } = values
    const loginUser = { email, password }
    try {
      const { data } = await axios.post(
        `${API_ENDPOINT}/v1/auth/login`,
        loginUser
      )
      setValues({ name: '', email: '', password: '' })
      setLoading(false)
      //saveUser(data.user)
      globalDispatch({ type: SAVE_USER, payload: data.user })
      navigate('/dashboard')
    } catch (error) {
      showAlert({ text: error.response.data.msg })
      setLoading(false)
    }
  }

  return (
    <div>
      {alert.show && (
        <div className={`alert alert-${alert.type}`}>{alert.text}</div>
      )}

      <Form title='Acessar Área Restrita' onSubmit={handleSubmit}>
        <TextInput
          label='Email:'
          name='email'
          value={values.email}
          onChange={handleChange}
          type='email'
        />
        <TextInput
          label='Senha:'
          name='password'
          value={values.password}
          onChange={handleChange}
          type='password'
        />

        <div>
          <LoadingButton
            loading={loading}
            variant='contained'
            type='submit'
            sx={{ mt: 1 }}
          >
            Entrar
          </LoadingButton>
        </div>
      </Form>
    </div>
  )
}

export default Login
