import * as React from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemButton from '@mui/material/ListItemButton'
import Divider from '@mui/material/Divider'
import { IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'

export default function DefaultList({
  items = [],
  handleDelete,
  handleClick = () => {},
}) {
  return (
    <List
      dense={true}
      sx={{
        width: '100%',
        maxWidth: 'md',
        bgcolor: '#222',
        mx: 'auto',
        mt: 1,
        boxShadow: 3,
      }}
    >
      {items.map((item) => {
        return (
          <React.Fragment key={`list-${item.id}`}>
            <ListItem
              secondaryAction={
                <IconButton
                  edge='end'
                  aria-label='delete'
                  onClick={() => handleDelete(item.id)}
                >
                  <DeleteIcon />
                </IconButton>
              }
            >
              <ListItemButton onClick={() => handleClick(item.id)}>
                <ListItemText
                  primaryTypographyProps={{
                    sx: { textTransform: 'uppercase' },
                  }}
                  secondaryTypographyProps={{ mt: 0.5 }}
                  primary={item.primary}
                  secondary={item.secondary}
                />
              </ListItemButton>
            </ListItem>
            <Divider component='li' variant='middle' sx={{ bgcolor: '#888' }} />
          </React.Fragment>
        )
      })}
    </List>
  )
}
