import axios from 'axios'
import { format } from 'date-fns/esm'
import React, { useEffect, useState } from 'react'
import { FaTimes } from 'react-icons/fa'
import { API_ENDPOINT } from '../../context/global'
import useVendas from '../../hooks/useVendas'
import {
  numberToCurrency,
  numberToShortCurrency,
} from '../../utils/currencyAndDate'
import useLocalState from '../../utils/localState'
import { DefaultList, SectionTitle, TextInput } from '../ui'
import { LoadingButton } from '@mui/lab'

import { Typography } from '@mui/material'

function VendasEvento({ evento, reloadEvento }) {
  const [vendasEvento, setVendasEvento] = useState([])
  const [itensVenda, setItensVenda] = useState([])
  const [pagamento, setPagamento] = useState('')
  const [totalVenda, setTotalVenda] = useState(0)
  const [observacao, setObservacao] = useState('')
  const { vendasPorEvento, fetchVendas, deleteVenda, loadingVendas } =
    useVendas()
  const { alert, showAlert, loading, setLoading } = useLocalState()

  const meiosPagamento = ['pix', 'cartão', 'dinheiro', 'troca']

  useEffect(() => {
    if (evento) {
      const vendas = vendasPorEvento(evento._id)
      setVendasEvento(vendas)
    }
  }, [evento, vendasPorEvento])

  const clearFields = () => {
    setItensVenda([])
    setPagamento('')
    setTotalVenda(0)
    setObservacao('')
  }

  const addVenda = async () => {
    const itens = itensVenda.map((itemVenda) => {
      const precoProd = numberToShortCurrency(itemVenda.preco)
      return { ...itemVenda, preco: precoProd }
    })

    const venda = {
      pagamento,
      data: new Date(),
      evento: evento._id,
      itens,
      observacao,
    }

    try {
      await axios.post(`${API_ENDPOINT}/v1/vendas/`, { ...venda })
      showAlert({
        text: 'Venda adiconada com sucesso.',
        type: 'success',
      })
      clearFields()
      await fetchVendas()
      setLoading(false)
    } catch (error) {
      console.log(error)
      showAlert({
        text: 'Erro ao adicionar venda. Verifique os campos e tente novamente.',
      })
      setLoading(false)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    addVenda()
  }

  const selectPagamento = (pag) => {
    setPagamento(pag)
  }

  const procuraProduto = (produto, preco) => {
    const itemVenda = itensVenda.find(
      (item) => item.produto === produto && item.preco === preco
    )
    if (!itemVenda)
      return {
        produto,
        quantidade: 0,
        preco: 0,
      }
    return itemVenda
  }

  const adicionaProduto = (produto, preco) => {
    let found = false
    let novosItens = itensVenda.map((item) => {
      if (item.produto === produto && item.preco === preco) {
        found = true
        return { ...item, quantidade: item.quantidade + 1 }
      } else return item
    })
    if (!found) {
      novosItens = [
        ...novosItens,
        {
          produto,
          quantidade: 1,
          preco,
        },
      ]
    }
    setItensVenda(novosItens)
  }

  const zeraProduto = (produto) => {
    const novosItens = itensVenda.filter((item) => item.produto !== produto)
    setItensVenda(novosItens)
  }

  useEffect(() => {
    let total = 0
    itensVenda.map((item) => {
      total += item.preco * item.quantidade
      return item
    })
    setTotalVenda(total)
  }, [itensVenda])

  const listaVendas = vendasEvento.map((venda) => {
    return {
      id: venda._id,
      primary: (
        <>
          <span>
            {numberToCurrency(venda.total)} | {venda.pagamento} |{' '}
            <i>
              {format(new Date(venda.data), 'dd/MM/y hh:mm')} |{' '}
              {venda.observacao}
            </i>
          </span>
        </>
      ),
      secondary: venda.itens.map((itemVenda) => {
        return (
          <p key={itemVenda._id} className='text-xs italic'>
            {itemVenda.quantidade}x {itemVenda.produto.nome} -{' '}
            {numberToCurrency(itemVenda.preco)}
          </p>
        )
      }),
    }
  })

  if (loading || loadingVendas) {
    return <div className='loading'></div>
  }

  return (
    <div>
      <div>
        {alert.show && (
          <div className={`alert alert-${alert.type}`}>{alert.text}</div>
        )}
      </div>

      <div className='rounded bg-zinc-800 text-white p-2 mx-auto md:max-w-4xl mb-1 shadow'>
        <span>NOVA VENDA</span>

        <form onSubmit={handleSubmit}>
          <div className=''>
            <div className='flex flex-col w-full justify-center'>
              {evento.produtos.map((produtoItem) => {
                const itemVenda = procuraProduto(
                  produtoItem.produto._id,
                  produtoItem.preco
                )
                return (
                  <div
                    className={`p-1 flex flex-row text-black rounded shadow m-1 align-middle hover:cursor-pointer ${
                      itemVenda.quantidade > 0 ? 'bg-amber-600' : 'bg-sky-400'
                    }`}
                    key={produtoItem._id}
                  >
                    <div
                      className='text-sm bg-sky-800 text-white rounded shadow py-2 px-0.5 w-5/6 align-middle text-left capitalize'
                      onClick={() =>
                        adicionaProduto(
                          produtoItem.produto._id,
                          produtoItem.preco
                        )
                      }
                    >
                      <span className='align-middle'>
                        {produtoItem.produto.nome} (
                        {numberToCurrency(produtoItem.preco)})
                      </span>
                    </div>
                    <div className='flex-1 flex items-center justify-center'>
                      <span className='font-bold text-md text-center align-middle'>
                        {itemVenda.quantidade}{' '}
                      </span>
                      <FaTimes
                        onClick={(e) => {
                          e.preventDefault()
                          zeraProduto(produtoItem.produto._id)
                        }}
                        className='ml-3 text-red-800 text-lg'
                      />
                    </div>
                  </div>
                )
              })}
            </div>
            <p>Pagamento</p>
            <div className='flex flex-row flex-wrap w-full justify-center'>
              {meiosPagamento.map((item, index) => {
                return (
                  <div
                    className={`${
                      item === pagamento
                        ? 'bg-blue-700 border-2 border-white '
                        : 'bg-purple-600 '
                    } p-0.5 rounded shadow m-0.5 flex-1 align-middle hover:cursor-pointer`}
                    key={`pagamento${index}`}
                    onClick={() => selectPagamento(item)}
                  >
                    <p className='text-sm uppercase'>{item}</p>
                  </div>
                )
              })}
            </div>
            <div className='mt-3'>
              <TextInput
                label='Observação'
                name='observacao'
                value={observacao}
                size={300}
                onChange={(e) => setObservacao(e.target.value)}
                compact={true}
                variant='filled'
              />
            </div>
            <div className='mt-3'>
              <Typography textTransform='uppercase'>
                Total: {numberToCurrency(totalVenda)}
              </Typography>
            </div>

            <LoadingButton
              loading={loading}
              variant='contained'
              type='submit'
              sx={{ mt: 1, height: '40px', fontWeight: 'bold' }}
            >
              registrar venda
            </LoadingButton>
          </div>
        </form>
      </div>

      <SectionTitle text='VENDAS DO EVENTO' />

      <div className='lista mt-3'>
        <DefaultList items={listaVendas} handleDelete={deleteVenda} />
      </div>
    </div>
  )
}

export default VendasEvento
