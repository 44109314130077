import { Link, Navigate } from 'react-router-dom'
import { GenericButton } from '../components/ui'
import { useGlobalContext } from '../context/global'

function Welcome() {
  const { user } = useGlobalContext()

  if (user) return <Navigate to='/dashboard' />

  return (
    <div className='page-container'>
      <div className='mt-4'>
        <h2>Ice Creamer</h2>
        <h4>By Sorvetário</h4>
        <div className='botoes text-center flex items-center justify-center'>
          <GenericButton destination='/login' text='Entrar' />
        </div>
      </div>
    </div>
  )
}

export default Welcome
