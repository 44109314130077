import React, { useEffect } from 'react'
import { useGlobalContext } from '../context/global'
import { LOGOUT } from '../actions/global'
import { Navigate } from 'react-router-dom'

function Logout() {
  const { globalDispatch } = useGlobalContext()

  useEffect(() => {
    globalDispatch({ type: LOGOUT })
  }, [])

  return <Navigate to='/' />
}

export default Logout
