import React from 'react'

import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'

function SelectInput({
  label,
  name,
  value,
  onChange,
  size = 360,
  options = [],
  fullWidth = false,
}) {
  return (
    // <div className='flex-1'>
    //   <label htmlFor={name} className='ml-2'>
    //     {label}{' '}
    //   </label>
    //   <br></br>
    //   <select
    //     name={name}
    //     id={name}
    //     value={value}
    //     onChange={onChange}
    //     className={`text-black rounded px-1 py-0.5 mb-3 ml-1 mr-1 bg-zinc-100 ${size} capitalize`}
    //   >
    //     {options.map((option) => {
    //       return (
    //         <option
    //           key={`${name}.${option.value}`}
    //           value={option.value}
    //           className='capitalize'
    //         >
    //           {option.text}
    //         </option>
    //       )
    //     })}
    //   </select>
    // </div>

    <FormControl sx={{ minWidth: size }} fullWidth={fullWidth}>
      <InputLabel id={`label-${name}`}>{label}</InputLabel>
      <Select
        labelId={`label-${name}`}
        id={name}
        name={name}
        value={value}
        label={label}
        onChange={onChange}
      >
        {options.map((option) => {
          return (
            <MenuItem key={`${name}.${option.value}`} value={option.value}>
              {option.text}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

export default SelectInput
