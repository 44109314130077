import React from 'react'
import { Link } from 'react-router-dom'

function GenericButton({ destination, text, icon }) {
  return (
    <Link
      to={destination}
      className='btn btn-pill bg-sky-400 hover:bg-sky-300 text-black'
    >
      {icon || ''}
      <span>{text}</span>
    </Link>
  )
}

export default GenericButton
