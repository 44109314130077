const numberToCurrency = (n) => {
  return (n / 100).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  })
}

const numberToShortCurrency = (n) => {
  return (n / 100).toLocaleString('pt-BR', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: false,
  })
}

const formatNumber = (n) => {
  return n.toLocaleString('pt-BR', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: false,
  })
}

const currencyToNumber = (currency) => {}

module.exports = {
  numberToCurrency,
  numberToShortCurrency,
  formatNumber,
}
