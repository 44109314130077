import React, { useEffect } from 'react'
import axios from 'axios'

import { API_ENDPOINT } from '../../context/global'

import { useState } from 'react'
import useProdutos from '../../hooks/useProdutos'
import useLocalState from '../../utils/localState'
import { DefaultList, Form, SectionTitle, SelectInput, TextInput } from '../ui'
import { useRef } from 'react'
import { numberToCurrency } from '../../utils/currencyAndDate'
import { LoadingButton } from '@mui/lab'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'

function ProdutosEvento({ evento, reloadEvento }) {
  const { produtos, fetchProdutoUnico, loadingProducts } = useProdutos()
  const [produto, setProduto] = useState('')
  const [quantidade, setQuantidade] = useState('')
  const [preco, setPreco] = useState('')
  const { alert, hideAlert, showAlert, loading, setLoading } = useLocalState()

  let quantidadeInput = useRef()

  const clearFields = () => {
    setProduto('')
    setQuantidade('')
    setPreco('')
  }

  const removeProduct = async (produto) => {
    hideAlert()
    try {
      await axios.post(
        `${API_ENDPOINT}/v1/eventos/removeProduto/${evento._id}`,
        { produto }
      )
      reloadEvento()
      showAlert({
        text: 'Produto removido com sucesso.',
        type: 'success',
      })
    } catch (error) {
      console.log(error)
      if (error.response.data.msg) {
        showAlert({
          text: error.response.data.msg,
        })
      } else {
        showAlert({
          text: 'Erro ao excluir produto',
        })
      }
    }
  }

  const addProduct = async () => {
    setLoading(true)
    try {
      await axios.post(
        `${API_ENDPOINT}/v1/eventos/adicionaProduto/${evento._id}`,
        { produto, quantidade, preco }
      )
      showAlert({
        text: 'Produto adiconado com sucesso.',
        type: 'success',
      })
      setTimeout(() => hideAlert(), 5000)
      clearFields()
      await reloadEvento()
      setLoading(false)
    } catch (error) {
      console.log(error)
      showAlert({
        text: 'Erro ao adicionar produto. Verifique os campos e tente novamente.',
      })
      setLoading(false)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    hideAlert()
    if (!produto || !quantidade || !preco) {
      showAlert({ text: 'Por favor informe produto, quantidade e preço' })
      return
    }
    await addProduct()
  }

  const handleProductChange = async (e) => {
    setProduto(e.target.value)
    try {
      const { preco: precoProd } = await fetchProdutoUnico(e.target.value)
      setPreco(
        (precoProd / 100).toLocaleString('pt-BR', {
          style: 'decimal',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          useGrouping: false,
        })
      )

      setTimeout(() => {
        if (quantidadeInput && quantidadeInput.current)
          quantidadeInput.current.focus()
      }, 500)
    } catch (error) {
      console.log(error)
    }
  }

  const produtosEvento = evento.produtos.map((produtoEvento) => {
    return {
      id: produtoEvento._id,
      primary: produtoEvento.produto.nome,
      secondary: `${produtoEvento.quantidade} un. |  ${numberToCurrency(
        produtoEvento.preco
      )}`,
    }
  })

  if (loadingProducts) {
    return <div className='loading'></div>
  }

  return (
    <div>
      <div>
        {alert.show && (
          <div className={`alert alert-${alert.type}`}>{alert.text}</div>
        )}
      </div>

      <div className='w-full'>
        <Form
          title='Adicionar Produto ao Evento'
          onSubmit={handleSubmit}
          flexRow={true}
        >
          <SelectInput
            label='Produto'
            onChange={handleProductChange}
            name='produto'
            value={produto}
            fullWidth={true}
            options={[
              { value: '', text: 'Selecione' },
              ...produtos.map((item) => {
                return { value: item._id, text: item.nome }
              }),
            ]}
          />
          <TextInput
            label='Quantidade'
            name='quantidade'
            value={quantidade}
            onChange={(e) => setQuantidade(e.target.value)}
            size={100}
            inputRef={quantidadeInput}
          />

          <TextInput
            label='Preço'
            name='preco'
            value={preco}
            onChange={(e) => setPreco(e.target.value)}
            size={100}
          />

          <LoadingButton
            loading={loading}
            variant='contained'
            type='submit'
            sx={{ mt: 1, height: '55px' }}
            startIcon={<AddCircleOutlineIcon />}
          >
            Adicionar
          </LoadingButton>
        </Form>
      </div>

      <SectionTitle text='PRODUTOS CADASTRADOS NO EVENTO' />

      <div className='lista mt-3'>
        <DefaultList items={produtosEvento} handleDelete={removeProduct} />
      </div>
    </div>
  )
}

export default ProdutosEvento
