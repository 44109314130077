import logo from './logo.svg'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import { ThemeProvider, createTheme } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'

import {
  Welcome,
  Login,
  Error,
  ProtectedRoute,
  Dashboard,
  Logout,
  ListaEventos,
  CriaEvento,
  MostraEvento,
  ListaProdutos,
  MostraProduto,
  CriaProduto,
} from './pages'
import ResponsiveAppBar from './components/Appbar'

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
})

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Router>
        <ResponsiveAppBar />
        <Routes>
          <Route path='/' element={<Welcome />} />
          <Route path='login' element={<Login />} />
          <Route path='logout' element={<Logout />} />

          {/* EVENTOS */}
          <Route
            path='eventos'
            element={
              <ProtectedRoute>
                <ListaEventos />
              </ProtectedRoute>
            }
          />
          <Route
            path='eventos/novo'
            element={
              <ProtectedRoute>
                <CriaEvento />
              </ProtectedRoute>
            }
          />
          <Route
            path='eventos/:id'
            element={
              <ProtectedRoute>
                <MostraEvento />
              </ProtectedRoute>
            }
          />

          {/* PRODUTOS */}
          <Route
            path='produtos'
            element={
              <ProtectedRoute>
                <ListaProdutos />
              </ProtectedRoute>
            }
          />
          <Route
            path='produtos/novo'
            element={
              <ProtectedRoute>
                <CriaProduto />
              </ProtectedRoute>
            }
          />
          <Route
            path='produtos/:id'
            element={
              <ProtectedRoute>
                <MostraProduto />
              </ProtectedRoute>
            }
          />

          <Route
            path='dashboard'
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route path='*' element={<Error />} />
        </Routes>
      </Router>
    </ThemeProvider>
  )
}

export default App
