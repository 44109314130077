import React, { useEffect, useState } from 'react'
import { Container } from '@mui/material'
import { Form } from '../components/ui'
import { LoadingButton } from '@mui/lab'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import {
  startOfMonth,
  startOfDay,
  endOfDay,
  isWithinInterval,
  toDate,
} from 'date-fns'

import { TextField } from '@mui/material'
import useVendas from '../hooks/useVendas'
import { Bar2D, Doughnut2D } from '../components/charts'
import { numberToCurrency } from '../utils/currencyAndDate'
import useLocalState from '../utils/localState'

function Dashboard() {
  const [periodStart, setPeriodStart] = useState(
    startOfDay(startOfMonth(new Date()))
  )
  const [periodEnd, setPeriodEnd] = useState(endOfDay(new Date()))
  const [filteredVendas, setFilteredVendas] = useState([])
  const [estatisticas, setEstatisticas] = useState({
    numVendas: 0,
    totalVendas: 0,
    totalUnidades: 0,
    pagamentos: [],
    produtosUnidade: [],
    produtosValor: [],
  })
  const { vendas, loadingVendas, calculaEstatVendas } = useVendas()
  const { loading, setLoading } = useLocalState()

  const handleSubmit = (e) => {
    e.preventDefault()
  }

  const filterVendas = () => {
    setLoading(true)
    if (vendas) {
      const fVendas = vendas.filter((venda) => {
        const dataVenda = toDate(new Date(venda.data))
        return isWithinInterval(dataVenda, {
          start: periodStart,
          end: periodEnd,
        })
      })
      setFilteredVendas(fVendas)
    }
    setLoading(false)
  }

  useEffect(() => {
    filterVendas()
  }, [vendas])

  useEffect(() => {
    setEstatisticas(calculaEstatVendas(filteredVendas))
  }, [filteredVendas])

  const anythingLoading = loadingVendas || loading

  if (anythingLoading) return <div className='loading'></div>

  return (
    <div className='page-container'>
      <Container maxWidth={false}>
        <h5 className='page-title'>Dashboard</h5>
        <div className='w-24 h-1 bg-sky-400 mx-auto mb-5'></div>

        <Form title='Selecionar Periodo' onSubmit={handleSubmit} flexRow={true}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              label='Início'
              ampm={false}
              value={periodStart}
              onChange={setPeriodStart}
              renderInput={(params) => (
                <TextField
                  size='small'
                  sx={{ mt: 2, width: '210px' }}
                  {...params}
                />
              )}
              inputFormat='dd/MM/yyyy HH:mm'
            />

            <DateTimePicker
              label='Fim'
              ampm={false}
              value={periodEnd}
              onChange={setPeriodEnd}
              renderInput={(params) => (
                <TextField
                  size='small'
                  sx={{ mt: 2, width: '210px' }}
                  {...params}
                />
              )}
              inputFormat='dd/MM/yyyy HH:mm'
            />
          </LocalizationProvider>
          <LoadingButton
            loading={anythingLoading}
            variant='contained'
            type='button'
            sx={{ mt: 1 }}
            onClick={filterVendas}
          >
            Filtrar
          </LoadingButton>
        </Form>

        <div className='text-left'>
          <div className='flex justify-center flex-wrap'>
            <div className='m-2'>
              <Doughnut2D
                caption='Vendas'
                data={estatisticas.pagamentos}
                subCaption={`${
                  estatisticas.numVendas
                } VENDAS | ${numberToCurrency(estatisticas.totalVendas)}`}
              />
            </div>

            <div className='m-2 flex-1'>
              <Bar2D
                caption='Produtos Vendidos - Unidades'
                data={estatisticas.produtosUnidade}
                subCaption={`${estatisticas.totalUnidades} UNIDADES VENDIDAS`}
              />
            </div>
            <div className='m-2'>
              <Bar2D
                caption='Produtos Vendidos - Faturamento'
                data={estatisticas.produtosValor}
                numberPrefix='R$ '
              />
            </div>
          </div>
          <br />
        </div>
      </Container>
    </div>
  )
}

export default Dashboard
