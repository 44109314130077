import axios from 'axios'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { API_ENDPOINT } from '../../context/global'
import { format } from 'date-fns'

import { Card, CardContent, Typography } from '@mui/material'

import ResumoEvento from '../../components/eventos/ResumoEvento'
import VendasEvento from '../../components/eventos/VendasEvento'
import ProdutosEvento from '../../components/eventos/ProdutosEvento'
import { BackButton, Tabs } from '../../components/ui'
import useLocalState from '../../utils/localState'

function MostraEvento() {
  const { id } = useParams()
  const [erro, setErro] = useState('')
  const [evento, setEvento] = useState({})
  const [activeComponent, setActiveComponent] = useState(
    <ResumoEvento evento={evento} />
  )
  const [tabs, setTabs] = useState([])

  const { loading, setLoading } = useLocalState()

  const fetchEvento = async () => {
    setErro('')
    setLoading(true)
    try {
      const { data } = await axios.get(`${API_ENDPOINT}/v1/eventos/${id}`)
      setEvento(data.evento)
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
      setErro('Erro ao carregar os dados')
    }
  }

  const reloadEvento = async () => {
    try {
      const { data } = await axios.get(`${API_ENDPOINT}/v1/eventos/${id}`)
      setEvento(data.evento)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchEvento()
  }, [])

  useEffect(() => {
    if (evento) {
      setTabs([
        { name: 'resumo', component: <ResumoEvento evento={evento} /> },
        {
          name: 'produtos',
          component: (
            <ProdutosEvento evento={evento} reloadEvento={reloadEvento} />
          ),
        },
        {
          name: 'vendas',
          component: (
            <VendasEvento evento={evento} reloadEvento={reloadEvento} />
          ),
        },
      ])
      setActiveComponent((ActiveComp) => {
        return {
          ...ActiveComp,
          props: { ...ActiveComp.props, evento: evento },
        }
      })
    }
  }, [evento])

  if (loading)
    return (
      <div className='page-container'>
        <div className='loading'></div>
      </div>
    )
  if (erro)
    return (
      <div className='page-container'>
        <h3>{erro}</h3>
        <div className='botoes text-center flex items-center justify-center mb-3'>
          <BackButton destination='/eventos' />
        </div>
      </div>
    )

  return (
    <div className='page-container'>
      <div className='botoes text-center flex items-center justify-center mb-3'>
        <BackButton destination='/eventos' />
      </div>

      <Card sx={{ display: 'inline-block' }}>
        <CardContent>
          <Typography variant='h6' component='div'>
            {evento.nome}
          </Typography>
          <Typography color='text.secondary'>
            {format(
              evento.data ? new Date(evento.data) : new Date(),
              'dd/MM/y HH:mm'
            )}
          </Typography>
        </CardContent>
      </Card>

      <Tabs
        tabs={tabs}
        defaultTab='resumo'
        changeComponent={setActiveComponent}
      />

      <div className='tab-content'>{activeComponent}</div>
    </div>
  )
}

export default MostraEvento
