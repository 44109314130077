import React from 'react'
import { Link } from 'react-router-dom'

function SubmitButton({ text, icon, disabled, customClasses = '' }) {
  return (
    <button
      type='submit'
      className={`btn btn-pill bg-sky-400 hover:bg-sky-300 text-black ${customClasses}`}
      disabled={disabled || false}
    >
      {icon || ''}
      <span>{text}</span>
    </button>
  )
}

export default SubmitButton
