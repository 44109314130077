import React from 'react'
import { Navigate } from 'react-router-dom'
import { useGlobalContext } from '../context/global'

const ProtectedRoute = ({ children }) => {
  const { user, isLoading } = useGlobalContext()
  if (isLoading) return <div className='loading'></div>
  if (!user) {
    return <Navigate to='/' />
  }
  return children
}
export default ProtectedRoute
