import React from 'react'
import { Box, Typography } from '@mui/material'

function SectionTitle({ text }) {
  return (
    <Box maxWidth='md' textAlign='left' sx={{ mx: 'auto' }}>
      <Typography
        component='span'
        borderBottom={3}
        borderColor='primary.main'
        paddingBottom='3px'
        sx={{ textTransform: 'uppercase' }}
      >
        {text}
      </Typography>
    </Box>
  )
}

export default SectionTitle
