import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'

function AddButton({ destination }) {
  return (
    <Button
      component={Link}
      to={destination}
      startIcon={<AddCircleOutlineIcon />}
      variant='contained'
    >
      Adicionar
    </Button>
  )
}

export default AddButton
