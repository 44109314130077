import React, { useContext, useEffect, useReducer } from 'react'
import axios from 'axios'

import { SAVE_USER, SET_LOADING } from '../actions/global'

import globalReducer from '../reducer/global'

const initialState = {
  isLoading: true,
  user: null,
  showLinks: false,
}

let API_ENDPOINT = ''
if (process.env.NODE_ENV === 'production') {
  API_ENDPOINT = '/api'
}

const AppContext = React.createContext()

const AppProvider = ({ children }) => {
  const [globalState, globalDispatch] = useReducer(globalReducer, initialState)

  const fetchUser = async () => {
    try {
      const { data } = await axios.get(`${API_ENDPOINT}/v1/auth/user`)
      globalDispatch({ type: SAVE_USER, payload: data.user })
    } catch (error) {
      console.log(error)
      globalDispatch({ type: SET_LOADING, payload: false })
    }
  }

  useEffect(() => {
    fetchUser()
  }, [])

  return (
    <AppContext.Provider value={{ ...globalState, globalDispatch }}>
      {children}
    </AppContext.Provider>
  )
}
// make sure use
export const useGlobalContext = () => {
  return useContext(AppContext)
}

export { AppContext, AppProvider, API_ENDPOINT }
